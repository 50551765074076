import { makeStyles } from '@material-ui/core'
import { graphql } from 'gatsby'
import * as React from 'react'
import { ReactElement } from 'react'
import { EmployeesComponent } from '../components/EmployeesComponent.tsx'
import Layout from '../components/Layout'

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
    fontSize: '1.5em',
    marginLeft: '10px'
  }
})

export const query = graphql`
  query PlEmplyeesQuery {
    allContentXlsxPlEmployees {
      nodes {
        name
        mail
        phone
        place
        position1
        position2
        title
      }
    }
  }
`

interface Employee {
  photo: string
  name: string
  mail: string
  orcid: string
  phone: string
  place: string
  position1: string
  position2: string
  title: string
}

interface Nodes {
  nodes: Array<Employee>
}

interface DataSheet {
  allContentXlsxPlEmployees: Nodes
}

interface Props {
  data: DataSheet
}

const Pracownicy = ({
  data: {
    allContentXlsxPlEmployees: { nodes: employees }
  }
}: Props): ReactElement => {
  const styles = useStyles()
  return (
    <Layout actualTab="Pracownicy">
      <>
        <p className={styles.title}>Pracownicy</p>
        <EmployeesComponent employees={employees} />
      </>
    </Layout>
  )
}

export default Pracownicy
